body {
    background-image: url('./image/background.jpg');
    background-size: cover; /* Растянуть изображение, чтобы оно покрывало весь фон */
}
 .rectangle-container {
  width: 800px; /* задайте нужную ширину */
  height: 600px; /* задайте нужную высоту */
  background-color: #eaeaea; /* задайте нужный цвет фона */
  margin: 0 auto;
  text-align: center;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 4px solid;
  border-color: #6494d8;
}
.parent-container {
  display: flex; /* Устанавливаем flex-контейнер */
  justify-content: center; /* Выравниваем дочерние элементы по горизонтали */
  align-items: center; /* Выравниваем дочерние элементы по вертикали */
  height: 100vh; /* Задаем высоту родительского контейнера для вертикального центрирования */
}
.button1 {
    padding: 15px;
    border-radius: 5px;
    border: 2px;
    background-color: #6494d8;
}
p {
    font-size: 24px;
}